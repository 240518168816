var config = {};
config.env = 'dev2';

//AWS
config.aws = {};
config.aws.apiRoot = "https://dev-api-gimsdev.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = 'ZGhmHMbS6v9GnX3xLQVGV7nzR1qWoPLR7Kfm35Gf';

export default config;
